type ModifierItemsToGroup = {
  modifier_item_uuid?: string;
  title: string;
  order?: number;
  modifier?: {
    uuid: string;
    title: string;
    order?: number;
  };
};

type GroupedOrderItemModifierItems = {
  uuid?: string;
  title: string;
  order?: number;
  items: { uuid?: string; title: string; order?: number }[];
};

export default function groupOrderItemModifierItems(
  input: ModifierItemsToGroup[]
): GroupedOrderItemModifierItems[] {
  const groupedModifiers: GroupedOrderItemModifierItems[] = [];
  for (const item of input) {
    const index = groupedModifiers.findIndex(
      (gMod) => gMod.uuid === item.modifier?.uuid
    );
    if (index === -1) {
      if (item.modifier?.uuid && item.modifier.title) {
        groupedModifiers.push({
          uuid: item.modifier.uuid,
          title: item.modifier.title,
          order: item.modifier.order,
          items: [
            {
              uuid: item.modifier_item_uuid,
              title: item.title,
              order: item.order,
            },
          ],
        });
      }
    } else {
      groupedModifiers[index].items.push({
        uuid: item.modifier_item_uuid,
        title: item.title,
        order: item.order,
      });
    }
  }
  groupedModifiers.sort(modifierSortFn);
  for (const item of groupedModifiers) {
    item.items.sort(modifierSortFn);
  }
  return groupedModifiers;
}

function modifierSortFn(a: { order?: number }, b: { order?: number }) {
  if (typeof a.order === 'number' && typeof b.order === 'number') {
    return a.order - b.order;
  }
  return 0;
}
