/**
 * Formats the price
 * @param price price in cents
 * @param currency string suspended
 * @returns
 */
export default function formatPrice(
  price: number = 0,
  currency: string = "€"
): string {
  return (
    (price / 100).toFixed(2).replace(".", ",") +
    (currency ? ` ${currency}` : "")
  );
}
